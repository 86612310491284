import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="207" height="9" viewBox="0 0 207 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.3415 8.33102C34.0855 7.42759 2.88072 8.25375 0.606264 7.99818C-1.25465 7.64156 1.60566 1.47805 3.3115 0.568684C3.91457 0.24773 4.621 0.18235 6.24069 0.354714C7.53299 0.485473 16.4241 0.467639 25.005 0.402259C33.5859 0.33688 41.8566 0.259614 41.8566 0.259614C42.2874 0.455753 43.6142 -0.180211 43.8554 0.0515895C44.2345 0.324995 57.8122 0.372544 71.8725 0.372544C85.9328 0.372544 100.476 0.354711 102.75 0.360654C105.524 0.366598 103.301 0.717274 106.575 0.610289C113.536 0.384432 128.665 0.883691 133.938 0.616229C138.176 0.40226 139.072 0.414147 143.104 0.681609C147.205 0.955015 169.192 0.996621 171.742 0.622174C172.362 0.515189 172.827 0.562737 172.827 0.699439C173.12 0.71727 188.283 1.11549 188.335 1.21653C188.438 1.35324 188.904 1.4305 189.352 1.34135C190.179 1.21059 206.703 1.01445 206.91 1.41862C207.547 2.57762 204.67 7.38004 202.654 8.5331C200.534 9.75154 190.041 8.12894 181.357 8.73518C133.989 8.00412 143.415 8.06356 132.714 8.50338C131.767 8.07544 127.941 9.04425 125.667 8.29536C124.736 7.98629 117.93 7.86742 116.379 8.14677C114.674 8.45584 80.574 7.30277 76.5765 8.17054C70.2356 7.40976 44.3378 8.19432 38.3415 8.33102Z" fill="#FFBA00" />
    </svg>

  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="56" height="9" viewBox="0 0 56 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3726 8.33102C9.22121 7.42759 0.779325 8.25375 0.164013 7.99818C-0.339423 7.64156 0.434381 1.47805 0.895865 0.568684C1.05902 0.24773 1.25013 0.18235 1.6883 0.354714C2.03791 0.485473 4.44323 0.467639 6.76463 0.402259C9.08603 0.33688 11.3235 0.259614 11.3235 0.259614C11.4401 0.455753 11.799 -0.180211 11.8643 0.0515895C11.9668 0.324995 15.64 0.372544 19.4438 0.372544C23.2475 0.372544 27.1818 0.354711 27.7971 0.360654C28.5476 0.366598 27.9463 0.717274 28.8319 0.610289C30.7152 0.384432 34.8079 0.883691 36.2343 0.616229C37.381 0.40226 37.6234 0.414147 38.7142 0.681609C39.8236 0.955015 45.7717 0.996621 46.4615 0.622174C46.6294 0.515189 46.7552 0.562737 46.7552 0.699439C46.8345 0.71727 50.9365 1.11549 50.9505 1.21653C50.9785 1.35324 51.1044 1.4305 51.2256 1.34135C51.4493 1.21059 55.9196 1.01445 55.9756 1.41862C56.148 2.57762 55.3696 7.38004 54.8242 8.5331C54.2508 9.75154 51.412 8.12894 49.0626 8.73518C36.2483 8.00412 38.7981 8.06356 35.9034 8.50338C35.647 8.07544 34.6121 9.04425 33.9968 8.29536C33.7451 7.98629 31.9038 7.86742 31.4843 8.14677C31.0228 8.45584 21.7978 7.30277 20.7163 8.17054C19.0009 7.40976 11.9948 8.19432 10.3726 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)