/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"


//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/toip.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/contact-center-omnicanal.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageVoiceApi/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageVoiceApi/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageVoiceApi/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageVoiceApi/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageVoiceApi.jpg"

import { TitleUnderlineVoiceApiOne, TitleUnderlineVoiceApiTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineVoiceApi"
import imageStartVoiceApi from "@components/pageFunctionalities/components/intro/images/imageStartVoiceApi.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageTwo.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

const titleStart = (
  <p>
    Una Voice API de baja latencia que te permite automatizar y escalar <TitleUnderlineVoiceApiOne underline="tus" /> <TitleUnderlineVoiceApiTwo underline="operaciones" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Ya sea para establecer comunicaciones a nivel global o para ofrecer interacciones altamente personalizadas, nuestra Voice API proporciona las herramientas necesarias para satisfacer las demandas cambiantes del mercado y las expectativas de los clientes.
        <br /><br />
        Contamos con una robusta infraestructura que garantiza la estabilidad y el rendimiento óptimo, incluso en entornos de alto volumen.
      </p>
    ),
    image: imageStartVoiceApi,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "ToIP: Qué es, ventajas y en qué se diferencia vs VoIP",
      textbody:
        "¿Quieres saber todo sobre el ToIP? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/toip/",
      card: true,
    },
    {
      image: article2,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
      card: true,
    },
    {
      image: article3,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
    {
      image: article4,
      title: "14 preguntas para elegir el mejor software de Contact Center",
      textbody:
        "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
      link: "https://beexcc.com/blog/mejor-software-call-center/",
    },
    {
      image: article5,
      title: "Contact Center omnicanal: Lo que todo líder de equipo debe saber",
      textbody:
        "Si quieres aprender sobre un Contact Center omnicanal, en este artículo creamos una guía con toda la información esencial.",
      link: "https://beexcc.com/blog/contact-center-omnicanal/",
    },
  ],
  contacts: {
    title: "¡Mejora los resultados de tus llamadas con nuestra Voice API!",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Notificaciones y alertas automatizadas",
      description: "<p>Automatiza las notificaciones de voz en gestiones como recordatorios de citas, de cobro o seguimiento de pedidos. Envía mensajes automáticos a una base de clientes específica y asegúrate de ofrecer una comunicación directa y personalizada.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Enrutamientos inteligentes",
      description: "<p>Mejora la contactabilidad transfiriendo a tus usuarios a los agentes adecuados mediante criterios de disponibilidad o especialización. Enruta tus llamadas al instante a los destinos deseados, ya sea números de teléfono externos o extensiones SIP internas.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones rápidas",
      description: "<p>Utilizamos SDK y WebRTC que te permite integrar todas las capacidades de voz a cualquier herramienta interna de tu empresa como Salesforce, HubSpot, SAP, Zoho u otros. Utiliza todas las capacidades de voz en el lugar que prefieras desde cualquier navegador web con cualquier dispositivo.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "IVR inteligente",
      description: "<p>Construye un autoservicio telefónico en pocos minutos que te permitan reducir la carga de atención telefónica. Además, permite un enrutamiento entre canales como a WhatsApp, así como al agente adecuado.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "Llamadas VoIP: ¿Cómo mejorar su rendimiento?",
    description: "<p>Aprende cómo garantizar una calidad de sonido excepcional, minimizar las interrupciones y maximizar la eficiencia de tus llamadas.<br /><br />Nuestro VoIP specialist, Julio Guarniz, te proporciona valiosos consejos para mejorar el rendimiento de tus llamadas con esta tecnología.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Customer Experience",
      title: "Atención al cliente",
      img: televentas,
      link: "/atencion-al-cliente/",
      text:
        "Configura encuestas de satisfacción para enviarlas luego de una atención telefónica. Así, obtienes información valiosa para mejorar tu atención al cliente.",
      colorTab: "#FFD96E",
      background: "#ffebb7"
    },
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Lanza llamadas automáticas luego de que tus leads hayan dejado sus datos en un formulario. Además, utiliza detección de voz para determinar si han sido contestadas por un humano.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Configura mensajes de voz personalizados para recordatorios de pago o alertas de impago y envíalos de manera automatizada a toda tu base de deudores.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
}

export default data
